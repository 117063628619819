export default [
  // *===============================================---*
  // *--------- SERVIZI / SERVICES---------------------------*
  // *===============================================---*
  {
    path: '/services/:id',
    name: 'details-services',
    component: () => import('@/views/pages/portfolio/services/details/Details.vue'),
    meta: {
      pageTitle: 'Dettaglio',
      breadcrumb: [
        {
          text: 'Liste',
        },
        {
          text: 'Lista Servizi',
          to: '/list-services',
        },
        {
          text: 'Dettaglio Servizio',
          active: true,
        },
      ],
    },
  },
]
