export default {
  namespaced: true,
  state: {
    productGeneral: {},
    productGeneralReset: {},
    productCaratteristiche: {},
    productCodici: [],
  },
  getters: {
    getProductGeneral(state) {
      return state.productGeneral
    },
    getProductGeneralReset(state) {
      return state.productGeneralReset
    },
    getProductCodici(state) {
      return state.productCodici
    },
    getProductCaratteristiche(state) {
      return state.productCaratteristiche
    },
  },
  mutations: {
    storeProductGeneral(state, product) {
      state.productGeneral = { ...product }
      // console.log('state.product', state.productGeneral)
    },
    storeProductGeneralReset(state, product) {
      state.productGeneralReset = { ...product }
    },
    storeProductCodici(state, codici) {
      state.productCodici = [...codici]
    },
    storeProductCaratteristiche(state, char) {
      state.productCaratteristiche = { ...char }
      // console.log(state.productCaratteristiche)
    },
    deleteProductCodeById(state, id) {
      const index = state.productCodici.findIndex(el => el.id === id)
      state.productCodici.splice(index, 1)
    },
  },
  actions: {
    setProductGeneralReset({ commit }, product) {
      commit('storeProductGeneralReset', product)
    },
    setProductGeneral({ commit }, product) {
      commit('storeProductGeneral', product)
    },
    setProductCodici({ commit }, codici) {
      commit('storeProductCodici', codici)
    },
    deleteProductCode({ commit }, id) {
      commit('deleteProductCodeById', id)
    },
    setProductCaratteristiche({ commit }, char) {
      commit('storeProductCaratteristiche', char)
    },
  },
}
