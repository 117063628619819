export default [
  // *===============================================---*
  // *--------- FORNITORI / SUPPLIERS--------------------------------*
  // *===============================================---*
/*   {
    path: '/supplier-edit',
    name: 'supplier-edit',
    component: () => import('@/views/pages/lists/supplier/edit/SupplierViewEdit.vue'),
    meta: {
      pageTitle: 'Modifica Fornitore',
      breadcrumb: [
        {
          text: 'Lista Fornitori',
          to: 'list-suppliers',
        },
        {
          text: 'Dettaglio Fornitore',
          to: 'supplier-details',
        },
        {
          text: 'Fornitore',
          active: true,
        },
      ],
    },
  }, */
  {
    path: '/supplier-details',
    name: 'supplier-details',
    component: () => import('@/views/pages/lists/supplier/details/SupplierView.vue'),
    meta: {
      pageTitle: 'Dettaglio Fornitore',
      breadcrumb: [
        {
          text: 'Lista Fornitori',
          to: 'list-suppliers',
        },
        {
          text: 'Fornitore',
          active: true,
        },
      ],
    },
  },
  {
    path: '/supplier-details2',
    name: 'supplier-details2',
    component: () => import('@/views/pages/lists/supplier/details/SupplierView.vue'),
    meta: {
      pageTitle: 'Dettaglio Fornitore',
      breadcrumb: [
        {
          text: 'Lista Fornitori',
          to: 'list-suppliers',
        },
        {
          text: 'Fornitore',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '/info-supplier/:id',
        name: 'info-supplier',
        component: () => import('@/views/pages/lists/supplier/details/nav-tabs/Information.vue'),
        meta: {
          pageTitle: 'Dettaglio Fornitore',
          breadcrumb: [
            {
              text: 'Lista Fornitori',
              to: '/list-supplier',
            },
            {
              text: 'Info Fornitore',
              active: true,
            },
          ],
        },
      },
      {
        path: '/documents-supplier/:id',
        name: 'documents-supplier',
        component: () => import('@/views/pages/lists/supplier/details/nav-tabs/Documents.vue'),
        meta: {
          pageTitle: 'Dettaglio Fornitore',
          breadcrumb: [
            {
              text: 'Lista Fornitori',
              to: '/list-supplier',
            },
            {
              text: 'Documenti Fornitore',
              active: true,
            },
          ],
        },
      },
      {
        path: '/interactions-supplier/:id',
        name: 'interactions-supplier',
        component: () => import('@/views/pages/lists/supplier/details/nav-tabs/InteractionsStoric.vue'),
        meta: {
          pageTitle: 'Dettaglio Fornitore',
          breadcrumb: [
            {
              text: 'Lista Fornitori',
              to: '/list-supplier',
            },
            {
              text: 'Interazioni Fornitore',
              active: true,
            },
          ],
        },
      },
      {
        path: '/stats-supplier/:id',
        name: 'stats-supplier',
        component: () => import('@/views/pages/lists/supplier/details/nav-tabs/SupplierStats.vue'),
        meta: {
          pageTitle: 'Statistiche Fornitore',
          breadcrumb: [
            {
              text: 'Lista Fornitori',
              to: '/list-Fornitore',
            },
            {
              text: 'Statistiche Fornitore',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
