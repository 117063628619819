import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAddressBook } from '@fortawesome/free-regular-svg-icons'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios'
// GOOGLE APIS
// import GoogleAPIs from 'vue-googleapis'

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import VuePapaParse from 'vue-papa-parse'

import { Tree } from 'element-ui'

import moment from 'moment'
import i18n from '@/libs/i18n'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import '@core/scss/vue/libs/vue-wizard.scss'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// fake db
import '@/@fake-db/db'
// import 'vue-good-table/dist/vue-good-table.css'
import '@core/scss/vue/libs/vue-good-table.scss'

// google calendar api
/* Vue.use(GoogleAPIs, {
  apiKey: 'AIzaSyBru1tgLgw5_84eowWVLxham3O-k15IPxk',
  clientId: '1091964279486-4r0cni30js0b0utmqieo951r64nc2q55.apps.googleusercontent.com',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
  scope: 'https://www.googleapis.com/auth/calendar.readonly',
}) */
// Vue.use(VueReCaptcha, { siteKey: 'localhost' })
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VuePapaParse)

// Composition API
Vue.use(VueCompositionAPI)

// tree category
Vue.use(Tree)
// Vue.use(TreeViewPlugin)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// for form wizard
require('@core/assets/fonts/feather/iconfont.css')

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
library.add(faAddressBook, faStopwatch)

// Vue.prototype.$urlServer = 'https://manager.petergoodstrong.com'

// const urlServer = 'https://manager.petergoodstrong.com'

Vue.config.productionTip = false

// gestire l'interceptor di axios con tutte le request api
axios.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    // if not auth, remove token from sessionStorage and managed redirect in beforeEach() in ./route/index
    // sessionStorage.removeItem('token')
    // document.location.href = '/login'
  }
  if (error.response.status === 404) {
    document.location.href = '/error-404'
  }
  return error
})
// console.log('res', res)return Promise.resolve(res)
new Vue({
  moment,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
