export default [
  // *===============================================---*
  // *--------- syncorder------------------------------*
  // *===============================================---*
  {
    path: '/sync-orders',
    name: 'sync-orders',
    component: () => import('@/views/beautyspacenails/SyncOrder.vue'),
    meta: {
      layout: 'full',
    },
  },
]
