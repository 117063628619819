export default [
  // *===============================================---*
  // *--------- PRODOTTI / PRODUCTS------------------------------*
  // *===============================================---*
  {
    path: '/products/:id',
    name: 'details-products',
    component: () => import('@/views/pages/portfolio/products/details/Details.vue'),
    meta: {
      pageTitle: 'Dettaglio',
      breadcrumb: [
        {
          text: 'Liste',
        },
        {
          text: 'Lista Prodotti',
          to: '/list-products',
        },
        {
          text: 'Dettaglio Prodotto',
          active: true,
        },
      ],
    },
  },
  {
    path: '/new-product',
    name: 'new-product',
    component: () => import('@/views/pages/portfolio/products/add/AddProducts.vue'),
    meta: {
      pageTitle: 'Aggiungi',
      breadcrumb: [
        {
          text: 'Liste',
        },
        {
          text: 'Lista Prodotti',
          to: '/list-products',
        },
        {
          text: 'Aggiungi Prodotto',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-not-associates',
    name: 'product-not-associates',
    component: () => import('@/views/pages/portfolio/ProductsNotAssociates.vue'),
    meta: {
      pageTitle: 'Prodotti non associati',
      breadcrumb: [
        {
          text: 'Non Associati',
        },
      ],
    },
  },
  {
    path: '/post',
    name: 'post',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      pageTitle: 'Post',
      breadcrumb: [
        {
          text: 'Post',
        },
      ],
    },
  },
]
