/* eslint-disable no-underscore-dangle */
export default {
  namespaced: true,
  state: {
    calendars: null,
  },
  getters: {
    getCalendars(state) {
      // console.log('dentro getters calendars')
      return state.calendars
    },
  },
  mutations: {
    setCalendar(state, calendar) {
      // console.log('calendar', calendar)
      // sessionStorage.setItem('google-calendar', JSON.stringify(status))
      state.calendars = calendar
      // console.log('calendar store set', calendar)
    },
  },
  actions: {
    async fetchCalendars1({ state }) {
      // console.log('context', state)
      const vm = this._vm
      vm.api.client.calendar.calendarList.list({
        minAccessRole: 'reader',
        showDeleted: false,
        showHidden: false,
      })
        .then(response => {
          // console.log('dentro vuex calendars')
          // Handle the results here (response.result has the parsed body).
          // console.log('Response', response.result.items)
          // vm.option = response.result.items.reduce
          state.calendars = response.result.items.map(item => ({ title: item.summary, value: item }))
          // console.log('this.option', vm.option)
        })
        .catch(e => e)
    },
    async fetchCalendars({ commit }) {
      const google = this._vm.$google
      const response = await google.api.client.calendar.calendarList.list({
        minAccessRole: 'reader',
        showDeleted: false,
        showHidden: false,
      })
      if ('result' in response && 'items' in response.result && response.result.items.length > 0) {
        // console.log('fetch calendars', response.result.items)
        response.result.items.map(item => ({ title: item.summary, value: item }))
        commit('setCalendar', response.result.items.map(item => ({ title: item.summary, value: item })))
      } else this.optionCalendar = []
    },
  },
}
