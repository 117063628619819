export default {
  namespaced: true,
  state: {
    unitaMisura: [],
    unitaUtilizzo: [],
    statoServizio: [],
  },
  getters: {
    getUnitaMisura(state) {
      return state.unitaMisura
    },
    getUnitaUtilizzo(state) {
      return state.unitaUtilizzo
    },
    getStatoServizio(state) {
      return state.statoServizio
    },
  },
  mutations: {
    storeUnitaMisura(state, unitaMisura) {
      state.unitaMisura = [...unitaMisura]
    // console.log('state.product', state.productGeneral)
    },
    storeUnitaMisuraById(state, unitaMisura) {
      const unitaMisuraArray = state.unitaMisura
      const index = unitaMisuraArray.findIndex(el => el.id === unitaMisura.id)
      if (index > 0) {
        // console.log('indexxxx', index)
        unitaMisuraArray[index] = { ...unitaMisura }
        state.unitaMisura.lenght = 0
        state.unitaMisura = [...unitaMisuraArray]
      } else {
        unitaMisuraArray.unshift(unitaMisura)
        state.unitaMisura.lenght = 0
        state.unitaMisura = [...unitaMisuraArray]
      }
      // console.log('state.product', state.productGeneral)
    },
    storeUnitaUtilizzoById(state, unitaUtilizzo) {
      const unitaMisuraArray = state.unitaUtilizzo
      const index = unitaMisuraArray.findIndex(el => el.id === unitaUtilizzo.id)
      if (index > 0) {
        // console.log('indexxxx', index)
        unitaMisuraArray[index] = { ...unitaUtilizzo }
        state.unitaUtilizzo.lenght = 0
        state.unitaUtilizzo = [...unitaMisuraArray]
      } else {
        unitaMisuraArray.unshift(unitaUtilizzo)
        state.unitaUtilizzo.lenght = 0
        state.unitaUtilizzo = [...unitaMisuraArray]
      }
    },
    storeStatoServizioById(state, statoServizio) {
      const statoServizioArray = state.statoServizio
      // console.log('statoServizio', statoServizio)
      const index = statoServizioArray.findIndex(el => el.id === statoServizio.id)
      if (index > 0) {
        // console.log('indexxxx', statoServizioArray[index])
        statoServizioArray[index] = { ...statoServizio }
        state.statoServizio.lenght = 0
        state.statoServizio = [...statoServizioArray]
      } else {
        statoServizioArray.unshift(statoServizio)
        state.statoServizio.lenght = 0
        state.statoServizio = [...statoServizioArray]
      }
      // console.log('state.product', state.productGeneral)
    },
    storeUnitaUtilizzo(state, unitaUtilizzo) {
      state.unitaUtilizzo = [...unitaUtilizzo]
    },
    storeStatoServizio(state, statoServizio) {
      state.statoServizio = [...statoServizio]
    },
  },
  actions: {
    setUnitaMisura({ commit }, unitaMisura) {
      commit('storeUnitaMisura', unitaMisura)
    },
    setUnitaMisuraById({ commit }, unitaMisura) {
      commit('storeUnitaMisuraById', unitaMisura)
    },
    setUnitaUtilizzo({ commit }, unitaUtilizzo) {
      commit('storeUnitaUtilizzo', unitaUtilizzo)
    },
    setUnitaUtilizzoById({ commit }, unitaUtilizzo) {
      commit('storeUnitaUtilizzoById', unitaUtilizzo)
    },
    setStatoServizioById({ commit }, statoServizio) {
      commit('storeStatoServizioById', statoServizio)
    },
    setStatoServizio({ commit }, statoServizio) {
      commit('storeStatoServizio', statoServizio)
    },
  },
}
