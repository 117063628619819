import axios from 'axios'
/*
  bookmarks[
    {
      icon: "gauge"
      isBookmarked: true
      route: "dashboard"
      title: "Dashboard"
    }
  ]
*/
export default {
  namespaced: true,
  state: {
    menu: null,
    menuApi: [],
    bookmarks: [],
    bookmarsToSearch: [],
    errorFetchAPi: '',
    // altri valori dell'utente
  },
  getters: {
    getMenu(state) {
      // console.log('stateMenuApi', state.menuApi)
      return state.menuApi
    },
    getBookmarks(state) {
      // console.log('ciao')
      return state.bookmarks
    },
    getBookmarksToSearch(state) {
      // console.log('dentroil get', state.bookmarsToSearch)
      return state.bookmarsToSearch
    },
    getMenuApi(state) {
      // console.log(state.menuApi)
      return JSON.parse(state.menu)
    },
  },
  mutations: {
    storeMenu(state, menu) {
      // console.log('storemenu,', menu)
      state.menuApi = [...menu]
      // state.menuApi = [...JSON.parse(menu)]
      // console.log(state.menuApi)
      // cambio le stringhe 'false' e 'true' in booleani
      state.menuApi.forEach((element, index) => {
        if (element.isActive === 'true' || element.isActive === 'false') {
          state.menuApi[index].isActive = element.isActive === 'true'
        }
        if (Object.prototype.hasOwnProperty.call(element, 'children')) {
          element.children.forEach((el, indexElementChildren) => {
            if (el.isActive === 'true' || el.isActive === 'false') {
              state.menuApi[index].children[indexElementChildren].isActive = el.isActive === 'true'
              state.menuApi[index].children[indexElementChildren].isBookmarked = el.isBookmarked === 'true'
            }
            if (Object.prototype.hasOwnProperty.call(el, 'children')) {
              el.children.forEach((elNested, indexNestedChildren) => {
                if (elNested.isActive === 'true' || elNested.isActive === 'false') {
                  state.menuApi[index].children[indexElementChildren].children[indexNestedChildren].isActive = elNested.isActive === 'true'
                  state.menuApi[index].children[indexElementChildren].children[indexNestedChildren].isBookmarked = elNested.isBookmarked === 'true'
                }
              })
            }
          })
        }
      })
      state.bookmarks.splice(0)
      state.bookmarsToSearch.splice(0)
    },
    setBooks(state, menu2) {
      // const menu = [...JSON.parse(menu2)]
      const menu = menu2
      // console.log('dentro setBooks')
      // console.log('dentro menu', menu)
      menu.forEach(element => {
        if (Object.prototype.hasOwnProperty.call(element, 'children')) {
          element.children.forEach(el => {
            if (!Object.prototype.hasOwnProperty.call(el, 'children')) {
              state.bookmarsToSearch.push(
                {
                  key: el.key,
                  title: el.title,
                  route: { name: el.route },
                  icon: el.icon,
                  isBookmarked: el.isBookmarked === 'true',
                },
              )
              // controlla il true stringa ricevuto dal server e lo setta booleano
              if (el.isBookmarked === 'true') {
                state.bookmarks.push({
                  key: el.key,
                  title: el.title,
                  route: el.route,
                  icon: el.icon,
                  isBookmarked: el.isBookmarked === 'true',
                })
              }
            }
            if (Object.prototype.hasOwnProperty.call(el, 'children')) {
              el.children.forEach(rchildrennested => state.bookmarsToSearch.push(
                {
                  key: rchildrennested.key,
                  icon: rchildrennested.icon,
                  route: { name: rchildrennested.route },
                  title: rchildrennested.title,
                  isBookmarked: rchildrennested.isBookmarked === 'true',
                },
              ))
            }
          })
        }
      })
      // console.log('bookmarks', state.bookmarks)
      // console.log('bookmarsToSearch', state.bookmarsToSearch)
    },
    pushBookmarks(state, route) {
      // console.log('route', route)
      // const findRouteToPush = state.bookmarks.findIndex(el => el.route === route.route)
      const findRouteToPush = state.bookmarks.findIndex(el => el.title === route.title)
      // console.log('findRouteToPush', findRouteToPush)
      if (findRouteToPush < 0) {
        // console.log('dentro find')
        state.bookmarks.push(route)
        state.menuApi.forEach((element, index1) => {
          if (Object.prototype.hasOwnProperty.call(element, 'children')) {
            element.children.forEach((el, index2) => {
              if (el.title === route.title) {
                // console.log('el', el)
                state.menuApi[index1].children[index2].isBookmarked = true // route.isBookmarked
              }
              if (Object.prototype.hasOwnProperty.call(el, 'children')) {
                el.children.forEach((el3, index3) => {
                  if (el3.title === route.title) {
                    // console.log('el3', el3)
                    state.menuApi[index1].children[index2].children[index3].isBookmarked = true // route.isBookmarked
                  }
                })
              }
            })
          }
        })
      } else {
        // console.log('dentro else')
        state.menuApi.forEach((element, index1) => {
          if (Object.prototype.hasOwnProperty.call(element, 'children')) {
            // console.log('element children', element)
            element.children.forEach((el, index2) => {
              if (el.title === route.title) {
                state.menuApi[index1].children[index2].isBookmarked = false // route.isBookmarked
              }
              if (Object.prototype.hasOwnProperty.call(el, 'children')) {
                el.children.forEach((el3, index3) => {
                  if (el3.title === route.title) {
                    state.menuApi[index1].children[index2].children[index3].isBookmarked = false // route.isBookmarked
                  }
                })
              }
            })
          }
        })
        state.bookmarks.splice(findRouteToPush, 1)
      }
      state.bookmarsToSearch.forEach((el, index) => {
        if (el.title === route.title) {
          // console.log('el before', el)
          state.bookmarsToSearch[index].isBookmarked = route.isBookmarked
          // console.log('el after', el)
        }
      })
      // console.log('state', state.menuApi)
      // console.log('lol', state.bookmarsToSearch)
      // console.log(state.menuApi[0].children[2].isBookmarked)
      // state.bookmarks.push(route)
      // state.bookmarks
    },
    storeErrorFetchAPi(state, error) {
      state.errorFetchAPi = error
    },
  },
  actions: {
    async getMenuUtente({ commit }) {
      // console.log('dentro getMenu utente VUEX')
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {}, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: String(JSON.parse(sessionStorage.getItem('activeAgency')).value),
          classe: 'Utente',
          metodo: 'getMenuUtente',
          ACL: '',
        },
      })
        .then(response => {
          // console.log('response', JSON.parse(response.data.menu))
          if (response.data.esito === 'OK') {
            commit('storeMenu', JSON.parse(response.data.menu))
            commit('setBooks', JSON.parse(response.data.menu))
          }
        })
        .catch(error => error)
    },
  },
}
