import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import authGoogle from './auth-google'
import products from './products'
import categories from './categories'
import controcorrente from './controcorrente'
import dispositivi from './dispositivi'
import dizionari from './dizionari'
import catalogs from './catalogs'
import calendarGoogle from './calendar-google'
import menuLogin from './menu-login'
import login from './login'
import logistic from './logistic'
import verticalMenu from './vertical-menu'
import modalEdit from './modal-edit'
import profile from './profile'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    authGoogle,
    calendarGoogle,
    menuLogin,
    login,
    logistic,
    dizionari,
    controcorrente,
    dispositivi,
    verticalMenu,
    modalEdit,
    profile,
    products,
    categories,
    catalogs,
  },
  strict: process.env.DEV,
})
