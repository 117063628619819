/* eslint-disable  */
export default {
    namespaced: true,
    state: {
      reparti: [],
    },
    getters: {
      getReparti(state) {
        return state.reparti
      },
    },
    mutations: {
      storeReparti(state, reparti) {
        state.reparti = [...reparti]
      // console.log('state.product', state.productGeneral)
      },
    },
    actions: {
      setReparti({ commit }, reparti) {
        commit('storeReparti', reparti)
      },
    },
  }
  