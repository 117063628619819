export default {
  namespaced: true,
  state: {
    dispositivi: [],
    dispSelected: {},
  },
  getters: {
    getDispositivi(state) {
      return state.dispositivi
    },
    getDispSelected(state) {
      // console.log('get', state.categories.find(el => el.id === state.idPadre))
      return state.dispSelected
    },
  },
  mutations: {
    storeDispositivi(state, dispositivi) {
      state.dispositivi = [...dispositivi]
    // console.log('state.product', state.productGeneral)
    },
    storeDispSelected(state, dispSelected) {
      state.dispSelected = { ...dispSelected }
    },
    storeUpdateDispositivo(state, dispositivo) {
      const { dispositivi } = state
      const dispositiviBackup = [...dispositivi]
      dispositivi.forEach((element, index) => {
        if (element.id === dispositivo.id) {
          state.dispositivi.length = 0
          state.dispositivi = [...dispositiviBackup]
          state.dispositivi[index] = { ...dispositivo }
        }
      })
    },
  },
  actions: {
    setDispositivi({ commit }, dispositivi) {
      commit('storeDispositivi', dispositivi)
    },
    setDispSelected({ commit }, dispositivo) {
      commit('storeDispSelected', dispositivo)
    },
    updateDispositivo({ commit }, dispositivo) {
      commit('storeUpdateDispositivo', dispositivo)
    },
  },
}
