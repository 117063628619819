import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle

// Routes
import auth from './routes/auth'
// import chat from './routes/chat'
import controcorrente from './routes/controcorrente'
import beautyspacenails from './routes/beautyspacenails'
// import inbox from './routes/inbox'
// import calendar from './routes/calendar'
import dashboard from './routes/dashboard'
import dizionari from './routes/dizionari'
import error from './routes/error'
import invoices from './routes/invoices'
import logistic from './routes/logistic'
import myorganization from './routes/myorganization'
// import patients from './routes/patients'
import profile from './routes/profile'
import portfolio from './routes/portfolio'
import products from './routes/products'
import quickAction from './routes/quick-action'
import services from './routes/services'
import settings from './routes/settings'
import supplier from './routes/supplier'
import proveinterne from './routes/proveinterne'
import fausto from './routes/fausto'
// import todo from './routes/todo'
// import users from './routes/users'
// import skipChooseConnectionPage from './utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'sync-orders' } },
    { path: '/', redirect: { name: 'fausto' } },
    // ...chat,
    // ...inbox,
    ...profile,
    ...auth,
    ...controcorrente,
    ...fausto,
    ...beautyspacenails,
    // ...calendar,
    ...dashboard,
    ...dizionari,
    ...error,
    ...invoices,
    ...logistic,
    ...myorganization,
    // ...patients,
    ...portfolio,
    ...products,
    ...quickAction,
    ...services,
    ...settings,
    ...supplier,
    ...proveinterne,
    // ...todo,
    // ...users,
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/blank-page',
      name: 'blank-page',
      component: () => import('@/views/BlankPage.vue'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = sessionStorage.getItem('token')
  // if (!isLoggedIn && (to.name !== 'sync-orders' && to.name !== 'login' && to.name !== 'reset-password' && to.name !== 'register' && to.name !== 'redirect-for-change-password')) {
  if (!isLoggedIn && (to.name !== 'fausto')) {
    return next({ name: 'fausto' })
  }
  // gestione login route su Login.vue. SOLO se ci sono due strutture si visualizza la pagina choose-connection
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
