// *===============================================---*
// *--------- Qucick-actions---------------------------------------*
// *===============================================---*
export default [
  {
    path: '/load-prices-products',
    name: 'load-prices-products',
    component: () => import('@/views/controcorrente/LoadPrices.vue'),
    meta: {
      pageTitle: 'Azioni Veloci',
      breadcrumb: [
        {
          text: 'Quick Actions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-actions',
    name: 'quick-actions',
    component: () => import('@/views/QuickActions.vue'),
    meta: {
      pageTitle: 'Azioni Veloci',
      breadcrumb: [
        {
          text: 'Quick Actions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/engagment',
    name: 'engagment',
    component: () => import('@/views/pages/quick-actions/Engagment.vue'),
    meta: {
      pageTitle: 'Azioni Veloci',
      breadcrumb: [
        {
          text: 'Engagment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/engagment1',
    name: 'engagment1',
    component: () => import('@/views/pages/quick-actions/Engagment1.vue'),
    meta: {
      pageTitle: 'Azioni Veloci',
      breadcrumb: [
        {
          text: 'Engagment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-storic-engagment',
    name: 'general-storic-engagment',
    component: () => import('@/views/pages/lists/interactions/StoricInteractions.vue'),
    meta: {
      pageTitle: 'Interazioni',
      breadcrumb: [
        {
          text: 'Storico Interazioni',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: () => import('@/views/pages/permissions/Permissions.vue'),
    meta: {
      pageTitle: 'Profilo',
      breadcrumb: [
        {
          text: 'Profilo',
          active: true,
        },
      ],
    },
  },
]
