/* eslint-disable  */
export default [
    {
      path: '/cirar',
      name: 'fausto',
      component: () => import('@/views/fausto/FaustoTabelle.vue'),
      meta: {
        layout: 'full',
      },
    },
  ]