export default {
  namespaced: true,
  state: {
    user: null,

    // altri valori dell'utente
  },
  getters: {
    getUser(state) {
      return state.user
    },
  },
  mutations: {
    storeUserInternal(state, user) {
      state.user = user
    },
    storeUser() {},
  },
  actions: {
    setUserToEdit({ commit }, formData) {
      commit('storeUserInternal', formData)
    },
  },
}
