export default [
  {
    path: '/organization',
    name: 'organization',
    component: () => import('@/views/pages/logistic/Organization.vue'),
    meta: {
      pageTitle: 'Organizzazione',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Gestione Organizzazione',
        },
      ],
    },
  },
  {
    path: '/organization-list',
    name: 'organization-list',
    component: () => import('@/views/pages/logistic/ListOrganization.vue'),
    meta: {
      pageTitle: 'Gestione Organizzazione',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Lista Punti Vendita',
        },
      ],
    },
  },
  {
    path: '/organization/:id',
    name: 'datails-organization',
    component: () => import('@/views/pages/logistic/details/DetailsOrganization.vue'),
    meta: {
      pageTitle: 'Organizzazione',
      breadcrumb: [
        {
          text: 'Le mie Organizzazioni',
          to: '/settings',
        },
        {
          text: 'Gestione Organizzazione',
        },
      ],
    },
  },
  {
    path: '/organization/:id/configuration',
    name: 'organization-configuration',
    component: () => import('@/views/pages/logistic/details/DetailsOrganizationConfiguration.vue'),
    meta: {
      pageTitle: 'Organizzazione',
      breadcrumb: [
        {
          text: 'Le mie Organizzazioni',
          to: '/settings',
        },
        {
          text: 'Gestione Organizzazione',
        },
        {
          text: 'Configurazione',
        },
      ],
    },
  },
  {
    path: '/flow-warehouse',
    name: 'flow-warehouse',
    component: () => import('@/views/pages/logistic/Flow.vue'),
    meta: {
      pageTitle: 'Magazzino',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Magazzino Generale',
        },
      ],
    },
  },
  {
    path: '/flow-warehouse-shop/:id',
    name: 'flow-warehouse-shop',
    component: () => import('@/views/controcorrente/details-shop/FlowShop.vue'),
    meta: {
      pageTitle: 'Magazzino',
      breadcrumb: [
        {
          text: 'Punti Vendita',
          to: '/organization',
        },
        {
          text: 'Magazzino Punto Vendita',
        },
      ],
    },
  },
  {
    path: '/serial-batches',
    name: 'serial-batches',
    component: () => import('@/views/pages/logistic/SerialBatches.vue'),
    meta: {
      pageTitle: 'Lotti Seriali',
      breadcrumb: [
        {
          text: 'Lotti Seriali',
        },
      ],
    },
  },
  {
    path: '/department',
    name: 'department',
    component: () => import('@/views/pages/logistic/Department.vue'),
    meta: {
      pageTitle: 'Reparti',
      breadcrumb: [
        {
          text: 'Reparti',
        },
      ],
    },
  },
  {
    path: '/warehouses',
    name: 'warehouses',
    component: () => import('@/views/pages/logistic/Warehouses.vue'),
    meta: {
      pageTitle: 'Logistica',
      breadcrumb: [
        {
          text: 'Magazzini',
        },
      ],
    },
  },
  {
    path: '/quantity-warehouses',
    name: 'quantity-warehouses',
    component: () => import('@/views/pages/logistic/QuantityWarehouses.vue'),
    meta: {
      pageTitle: 'Logistica',
      breadcrumb: [
        {
          text: 'Situazione Magazzini',
        },
      ],
    },
  },
  {
    path: '/situation-warehouse',
    name: 'situation-warehouse',
    component: () => import('@/views/pages/logistic/warehouse/Situation.vue'),
    meta: {
      pageTitle: 'Situazione Magazzino',
      breadcrumb: [
        {
          text: 'Situazione',
        },
      ],
    },
  },
  {
    path: '/inventary-warehouse',
    name: 'inventary-warehouse',
    component: () => import('@/views/pages/logistic/warehouse/Inventary.vue'),
    meta: {
      pageTitle: 'Invantario Magazzino',
      breadcrumb: [
        {
          text: 'Magazzino Invantario',
        },
      ],
    },
  },
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/views/pages/logistic/Devices.vue'),
    meta: {
      pageTitle: 'Dispositivi',
      breadcrumb: [
        {
          text: 'Gestione Dispositivi',
        },
      ],
    },
  },
]
