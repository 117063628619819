// import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    general: {},
    generalReset: {},
    preferences: [],
    address: [],
    addressReset: [],
    selectedPreferences: [],
    resetPreferences: [],
    preferencesFor: [],
    // altri valori dell'utente
  },
  getters: {
    getGeneralInformation(state) {
      return state.general
    },
    getPreferences(state) {
      return state.preferences
    },
    getAddress(state) {
      return state.address
    },
    getPreferencesFor(state) {
      return state.preferencesFor
    },
  },
  mutations: {
    storeGeneralInformation(state, general) {
      state.general = { ...general }
      // console.log('storeGeneralInformation', state.general)
    },
    storeGeneralInformationReset(state) {
      state.generalReset = { ...state.general }
      // console.log('storeGeneralInformationReset', state.generalReset)
    },
    storeAddress(state, address) {
      state.address = [...address]
      state.address = state.address.map(el => ({ ...el, isCollapsed: false, disabled: true }))
      // Vue.set(state.address, 'isCollapsed', false)
      // console.log(state.address)
    },
    storeAddressReset(state) {
      state.addressReset = [...state.address]
      // state.addressReset = state.address.map(el => ({ ...el, isCollapsed: false, disabled: true }))
      // Vue.set(state.address, 'isCollapsed', false)
      // console.log(state.address)
    },
    storePreferences(state, preferences) {
      // console.log(preferences)
      state.preferences = preferences.map(el => (
        {
          codice: el.codice,
          valore:
          {
            valore: el.valore, codice: el.codice, testo: el.testo, disabled: el.codice === 'TERMINI_E_CONDIZIONI',
          },
          testo: el.testo,
          disabled: el.codice === 'TERMINI_E_CONDIZIONI',
        }
      ))
      state.selectedPreferences = state.preferences.filter(el => Object.prototype.hasOwnProperty.call(el.valore, 'valore')).filter(item => item.valore.valore === 'Y').map(item1 => ({
        valore: item1.valore.valore, codice: item1.codice, testo: item1.testo, disabled: item1.codice === 'TERMINI_E_CONDIZIONI',
      }))
      // console.log('state.selectedPreferences', state.selectedPreferences)
      state.resetPreferences = [...state.selectedPreferences]
      // state.preferencesReset = preferences
    },
    resetPreferencesOnReset(state) {
      state.selectedPreferences = state.resetPreferences
    },
    storePreferencesFor(state, preferences) {
      state.preferencesFor = preferences
    },
    storeSelectedPreferences(state, preferences) {
      state.selectedPreferences = preferences
    },
  },
  actions: {
    getUserFromServer({ commit }, user) {
      // console.log('user action', user)
      commit('storeGeneralInformation', user)
      commit('storeGeneralInformationReset')
    },
    setPreferences({ commit }, preferences) {
      // console.log('action setPreferences')
      commit('storePreferences', preferences)
    },
    setAddressFromServer({ commit }, address) {
      // console.log('action setPreferences')
      commit('storeAddress', address)
      commit('storeAddressReset')
    },
    updateAddressToServer() {
      // console.log(address)
    },
  },
}
