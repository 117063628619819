export default {
  namespaced: true,
  state: {
    categories: [],
    idPadre: undefined,
  },
  getters: {
    getCategories(state) {
      return state.categories
    },
    getPadreObj(state) {
      // console.log('get', state.categories.find(el => el.id === state.idPadre))
      return state.categories.find(el => el.id === state.idPadre)
    },
  },
  mutations: {
    storeCategories(state, categories) {
      state.categories = [...categories]
    // console.log('state.product', state.productGeneral)
    },
    setPadreId(state, id) {
      state.idPadre = id
    },
  },
  actions: {
    setCategories({ commit }, categories) {
      commit('storeCategories', categories)
    },
    setPadreByID({ commit }, id) {
      commit('setPadreId', id)
    },
  },
}
