/* eslint-disable  */
export default {
    namespaced: true,
    state: {
      plu: [],
    },
    getters: {
      getPlui(state) {
        return state.plu
      },
    },
    mutations: {
      storePlu(state, plu) {
        const arrayAppoggio = []
        state.plu = [...plu]
        plu.forEach((plu, indexPlu) => {
          state.plu.slice(indexPlu+1, plu.length).forEach(pluAgain => {
            if (plu.id === pluAgain.id && plu.idProdotto === pluAgain.idProdotto) {
              arrayAppoggio.push({ ...plu, reparti: [ plu.reparti[0], pluAgain.reparti[0]] })
            }
          })
        })
        state.plu.forEach(el => {
          /// let elemento = {}
          if (arrayAppoggio.every(arr => arr.id !== el.id)) {
            // console.log('el', el)
            arrayAppoggio.push({ ...el, reparti: [el.reparti[0]] })
          }
        })
      // console.log('state.product', state.productGeneral)
      state.plu = [...arrayAppoggio]
      },
    },
    actions: {
      setPlu({ commit }, plu) {
        commit('storePlu', plu)
      },
    },
  }
