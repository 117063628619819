export default [
  {
    path: '/my-organization',
    name: 'my-organization',
    component: () => import('@/views/pages/settings/organization/MyOrganization.vue'),
    meta: {
      pageTitle: 'Organizzazione',
      breadcrumb: [
        {
          text: 'La mia Organizzazione',
          active: true,
        },
      ],
    },
  },
]
