/* eslint-disable no-underscore-dangle */
const STATUS_LOADING = 'loading'
const STATUS_READY = 'ready'

export default {
  namespaced: true,
  state: {
    status: STATUS_LOADING,
    signedIn: null,
    user: null,
    error: null,
  },
  mutations: {
    setStatus(state, status) {
      // console.log('status', status)
      // sessionStorage.setItem('google-calendar', JSON.stringify(status))
      state.status = status
    },
    setSignedIn(state, signedIn) {
      // console.log('signedIn', signedIn)
      state.signedIn = signedIn
    },
    setError(state, error) {
      state.error = error
    },
    setUser(state, user) {
      // console.log('usersetuser', user)
      if (user.Zb || user.Xb) sessionStorage.setItem('google-calendar-user', JSON.stringify(user))
      state.user = user
    },
  },
  actions: {
    async init(context) {
      // console.log('sono dentro init')
      // eslint-disable-next-line no-underscore-dangle
      const google = this._vm.$google
      // console.log('google', google)
      const load = setInterval(() => {
        if (google.isInit) {
          context.commit('setStatus', STATUS_READY)
          context.commit(
            'setSignedIn',
            google.api.auth2.getAuthInstance().isSignedIn.get(),
          )
          google.api.auth2.getAuthInstance().isSignedIn.listen(signedId => {
            context.commit('setSignedIn', signedId)
          })
          google.api.auth2.getAuthInstance().currentUser.listen(user => {
            context.commit('setUser', user)
          })
          clearInterval(load)
        }
      })
    },
    async signIn(context) {
      const google = this._vm.$google
      try {
        await google.api.auth2.getAuthInstance().signIn()
      } catch (e) {
        // console.error(e)
        context.commit('setError', e.error)
      }
    },
    async signOut(context) {
      const google = this._vm.$google
      try {
        await google.api.auth2.getAuthInstance().signOut()
      } catch (e) {
        // console.error(e)
        context.commit('setError', e.error)
      }
    },
    async disconnect(context) {
      const google = this._vm.$google
      try {
        await google.api.auth2.getAuthInstance().disconnect()
      } catch (e) {
        // console.error(e)
        context.commit('setError', e.error)
      }
    },
  },
  getters: {
    isReady(state) {
      return state.status === STATUS_READY
    },
    isSignedIn(state) {
      // console.log('getters in', state.signedIn)
      return state.signedIn === true
    },
    getUser(state) {
      return state.user
    },
    // getisSignedIn = state => state.signedIn
  },
}
