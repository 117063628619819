export default [
  // *===============================================---*
  // *--------- PREFERENZE------------------------------*
  // *===============================================---*
  {
    path: '/load-multiple-ddt',
    name: 'multiple-ddt',
    component: () => import('@/views/controcorrente/MultipleDDT.vue'),
    meta: {
      pageTitle: 'Caricamento DDT',
      breadcrumb: [
        {
          text: 'Inserimento Multiplo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/load-multiple-ddt/:store',
    name: 'multiple-ddt-single-store',
    component: () => import('@/views/controcorrente/details-shop/MultipleDDT.vue'),
    meta: {
      pageTitle: 'Caricamento DDT',
      breadcrumb: [
        {
          text: 'Singolo DDT',
          active: true,
        },
      ],
    },
  },
  {
    path: '/load-price-plu',
    name: 'load-price-plu',
    component: () => import('@/views/controcorrente/LoadPricePlu.vue'),
    meta: {
      pageTitle: 'Configurazione Prezzi',
      breadcrumb: [
        {
          text: 'Imposta Prezzi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sending-status',
    name: 'sending-status',
    component: () => import('@/views/controcorrente/StatoInviBilance.vue'),
    meta: {
      pageTitle: 'Stato Invio',
      breadcrumb: [
        {
          text: 'Stato Invio alle bilance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/import-products',
    name: 'import-products',
    component: () => import('@/views/controcorrente/MultipleDDTPrices.vue'),
    meta: {
      pageTitle: 'Importa Prodotti/Prezzi',
      breadcrumb: [
        {
          text: 'Importa Prodotti Prezzi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/load-price-plu/:shop',
    name: 'load-price-plu-shop',
    component: () => import('@/views/controcorrente/details-shop/LoadPricePlu.vue'),
    meta: {
      pageTitle: 'Configurazione Prezzi',
      breadcrumb: [
        {
          text: 'Imposta Prezzi',
          active: true,
        },
      ],
    },
  },
  // da eliminare
  {
    path: '/quantity-warehouse-shop/:shop',
    name: 'quantity-warehouse-shop',
    component: () => import('@/views/controcorrente/details-shop/LoadQuantityShops.vue'),
    meta: {
      pageTitle: 'Quantità Magazzino',
      breadcrumb: [
        {
          text: 'Movimenti Magazzino',
          active: true,
        },
      ],
    },
  },
]
