// *===============================================---*
// *--------- Auth---------------------------------------*
// *===============================================---*
export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    // redirectIfLoggedIn: true,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/choose-connection',
    name: 'choose-connection',
    component: () => import('@/views/pages/authentication/ChooseConnectionAtLogin.vue'),
    // redirectIfLoggedIn: true,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    // redirectIfLoggedIn: true,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/auth-forgot-password',
    name: 'auth-forgot-password-v2',
    component: () => import('@/views/pages/authentication/ChangePassword-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/password/reset/:token',
    name: 'redirect-for-change-password',
    component: () => import('@/views/pages/authentication/RedirectForChangePassword.vue'),
    meta: {
      layout: 'full',
    },
  },
]
