export default [
  // *===============================================---*
  // *--------- IMPOSTAZIONI ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/pages/settings/SettingsSimple.vue'),
    meta: {
      pageTitle: 'Impostazioni Basi',
      breadcrumb: [
        {
          text: 'Impostazioni',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-users',
    name: 'general-users',
    component: () => import('@/views/pages/settings/generalSettings/Users.vue'),
    meta: {
      pageTitle: 'Gestione Utenti',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Gestione Utenti',
        },
      ],
    },
  },
  {
    path: '/quill-editor',
    name: 'quill-editor',
    component: () => import('@/views/pages/settings/generalSettings/quill-editor/QuillEditor.vue'),
    meta: {
      pageTitle: 'Gestione Utenti',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Quill Editor',
        },
      ],
    },
  },
  {
    path: '/set-attributes',
    name: 'set-attributes',
    component: () => import('@/views/pages/settings/generalSettings/setAttributes/SetAttributes.vue'),
    meta: {
      pageTitle: 'Set Attributi',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Lista Attributi',
        },
      ],
    },
  },
  {
    path: '/details-set-attribute/:id',
    name: 'details-set-attribute',
    component: () => import('@/views/pages/settings/generalSettings/setAttributes/DetailsSetAttribute.vue'),
    meta: {
      pageTitle: 'Set Attributi',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Dettaglio Attributo',
        },
      ],
    },
  },
  {
    path: '/details-attribute/:id',
    name: 'details-attribute',
    component: () => import('@/views/pages/settings/generalSettings/setAttributes/DetailsAttribute.vue'),
    meta: {
      pageTitle: 'Set Attributi',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Dettaglio Attributo',
        },
      ],
    },
  },
  {
    path: '/manage-category',
    name: 'manage-category',
    component: () => import('@/views/pages/settings/generalSettings/manageCategory/ManageCategory.vue'),
    meta: {
      pageTitle: 'Gestione Catagorie',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Categorie',
        },
      ],
    },
  },
  {
    path: '/register-account',
    name: 'register-account-internal',
    component: () => import('@/views/pages/settings/generalSettings/userDetails/pages/CreateInternalAccount.vue'),
    meta: {
      pageTitle: 'Registrazione interna',
      breadcrumb: [
        {
          text: 'Utenti',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- CONFIGURAZIONE EMAIL--------------------*
  // *===============================================---*
  {
    path: '/configuration-email',
    name: 'configuration-email',
    component: () => import('@/views/pages/settings/email/Configurations.vue'),
    meta: {
      pageTitle: 'Configurazione Email',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Email',
          active: true,
        },

      ],
    },
  },
  {
    path: '/manage-devices',
    name: 'manage-devices',
    component: () => import('@/views/pages/settings/manage-devices/ListDevicesBalance.vue'),
    meta: {
      pageTitle: 'Gestione Dispositivi',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Devices',
          active: true,
        },

      ],
    },
  },
  /* {
    path: '/manage-device/:id',
    name: 'manage-device',
    component: () => import('@/views/pages/settings/manage-devices/details/DetailsDeviceTabs.vue'),
    meta: {
      pageTitle: 'Gestione Dispositivi',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Devices',
          active: true,
        },

      ],
    },
  }, */
  {
    path: '/manage-plu',
    name: 'manage-plu',
    component: () => import('@/views/pages/settings/manage-plu/ListPlu.vue'),
    meta: {
      pageTitle: 'Gestione Plu',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Gestione Plu',
          active: true,
        },

      ],
    },
  },
  {
    path: '/manage-plu-multiple-association',
    name: 'manage-plu-multiple-association',
    component: () => import('@/views/pages/settings/manage-plu/ListPluShops.vue'),
    meta: {
      pageTitle: 'Gestione Plu',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Gestione Plu',
          active: true,
        },

      ],
    },
  },
  {
    path: '/modules-email',
    name: 'modules-email',
    component: () => import('@/views/pages/settings/email/Modules.vue'),
    meta: {
      pageTitle: 'Moduli Email',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Email',
          active: true,
        },

      ],
    },
  },
  {
    path: '/additional-modules',
    name: 'additional-modules',
    component: () => import('@/views/pages/settings/advanced-settings/AdditionalModules.vue'),
    meta: {
      pageTitle: 'Moduli Email',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Email',
          active: true,
        },

      ],
    },
  },
]
