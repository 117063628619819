export default {
  namespaced: true,
  state: {
    catalogs: [],
    shops: [],
  },
  getters: {
    getCatalogs(state) {
      return state.catalogs
    },
    getShops(state) {
      return state.shops
    },
  },
  mutations: {
    storeCatalogs(state, catalogs) {
      state.catalogs = [...catalogs]
    },
    storeShops(state, shops) {
      state.shops = [...shops]
    },
  },
  actions: {
    setCatalogs({ commit }, catalogs) {
      commit('storeCatalogs', catalogs)
    },
    setShops({ commit }, shops) {
      commit('storeShops', shops)
    },
  },
}
