export default [
  {
    path: '/personal-profile',
    name: 'personal-profile',
    component: () => import('@/views/pages/profile/profile-setting/ProfileSetting.vue'),
    meta: {
      pageTitle: 'Modifica Profilo',
      breadcrumb: [
        {
          text: 'Profilo',
          to: '/personal-profile',
        },
        {
          text: 'Modifica Profilo',
          active: true,
        },
      ],
    },
  },
]
