export default [
  // *===============================================---*
  // *--------- Portfolio ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/prices-list',
    name: 'prices-list',
    component: () => import('@/views/pages/portfolio/PricesList.vue'),
    meta: {
      pageTitle: 'Listini',
      breadcrumb: [
        {
          text: 'Lista Listini',
          active: true,
        },
      ],
    },
  },
  /* {
    path: '/catalogs/:id',
    name: 'catalogs-details',
    component: () => import('@/views/pages/portfolio/catalogs-details/CatalogDetails.vue'),
    meta: {
      pageTitle: 'Cataloghi',
      breadcrumb: [
        {
          text: 'Dettaglio Catalogo',
          active: true,
        },
      ],
    },
  }, */
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/pages/portfolio/CategoriesNew.vue'),
    meta: {
      pageTitle: 'Categorie',
      breadcrumb: [
        {
          text: 'Categorie',
          active: true,
        },
      ],
    },
  },
  {
    path: '/catalogs',
    name: 'catalogs',
    component: () => import('@/views/pages/portfolio/Catalogs.vue'),
    meta: {
      pageTitle: 'Cataloghi',
      breadcrumb: [
        {
          text: 'Cataloghi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/pages/portfolio/Products.vue'),
    meta: {
      pageTitle: 'Prodotti',
      breadcrumb: [
        {
          text: 'Prodotti',
          active: true,
        },
      ],
    },
  },
/*   {
    path: '/services',
    name: 'services',
    component: () => import('@/views/pages/portfolio/Services.vue'),
    meta: {
      pageTitle: 'Servizi',
      breadcrumb: [
        {
          text: 'Servizi',
          active: true,
        },
      ],
    },
  }, */
]
