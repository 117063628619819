export default [
  {
    path: '/iva',
    name: 'iva',
    component: () => import('@/views/pages/settings/aliquote/AliquotaIva.vue'),
    meta: {
      pageTitle: 'Fisco',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
        {
          text: 'Fisco',
          to: '/iva',
        },
      ],
    },
  },
  {
    path: '/diz',
    name: 'diz',
    component: () => import('@/views/pages/dizionari/DizionariTabs.vue'),
    meta: {
      pageTitle: 'Dizionari',
      breadcrumb: [
        {
          text: 'Impostazioni',
          to: '/settings',
        },
      ],
    },
  },
]
