import axios from 'axios'
// import router from '@/router/index'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    apiKey: null,
    userEmail: null,
    errorLogin: '',
    changePasswordError: '',
    errorResetPassword: '',
    errorRegistration: '',
    // inserire valore di default e richiamarlo nella sua pagina
    user: null,
    // altri valori dell'utente
  },
  getters: {
    getLoginError(state) {
      return state.errorLogin
    },
    getChangePasswordError(state) {
      return state.changePasswordError
    },
    getResetPasswordError(state) {
      return state.errorResetPassword
    },
    getRegisterErrorMessage(state) {
      return state.errorRegistration
    },
  },
  mutations: {
    errorLogin(state, loginErrorMessage) {
      state.errorLogin = loginErrorMessage
    },
    errorChangePassword(state, changePasswordErrorMessage) {
      state.changePasswordError = changePasswordErrorMessage
    },
    errorResetPassword(state, errorResetPasswordMessage) {
      state.errorResetPassword = errorResetPasswordMessage
    },
    errorRegistration(state, errorRegistrationMessage) {
      state.errorRegistration = errorRegistrationMessage
    },
    authUser(state, userData) {
      state.apiKey = userData.apiKey
      // state.idUser = userData.idUser
    },
    storeUserInternal(state, user) {
      state.user = user
    },
    clearAuthData(state) {
      state.idToken = null
      state.idUser = null
    },
    storeUser() {},
  },
  actions: {
    // setta auto logout quando di 1ora
    setLogout({ dispatch }, expireTime) {
      setTimeout(() => {
        dispatch('logout')
      }, expireTime * 1000)
    },
    PROVA_T({ commit }, data) {
      // console.log('prova su auth-user', data)
      // console.log('ctx su auth-user', commit)
      commit('storeUserInternal', data)
      router.push({ name: 'home' })
    },
    async login({ commit }, formData) {
      // console.log('loginFinale', formData)
      await axios.post('https://manager.petergoodstrong.com/api/login', {
        email: formData.userEmail,
        password: formData.password,
        // returnSecureToken: true,
      })
        .then(() => {
          // console.log('sei dentro lo store/login')
          // console.log(res)
          // commit('errorLogin', '')
          // localStorage.setItem('token', res.token)
        /* // prendere i vlaori della response
          commit('authUser', {
            token: res.data.apiKey,
            idUser: res.data.localId,
          })
          commit('storeUserInternal', formData)
          this.$router.push({ name: 'home' }) */
        })
        .catch(() => {
          // console.log(e)
          const notAuth401 = 'Email o Password Errati!'
          commit('errorLogin', notAuth401)
        })
    },
    async register({ commit }, formData) {
      // console.log('register', formData)
      await axios.post('https://manager.petergoodstrong.com/api/register', {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        ruolo: formData.ruolo,
        // returnSecureToken: true,
      })
        .then(() => {
          // console.log('sei dentro lo store/register')
          // console.log(res)
          commit('errorRegistration', '')
        })
        .catch(() => {
          // console.log(e)
          const error = 'Email Errata!'
          commit('errorRegistration', error)
        })
    },
    async logout({ commit }, email) {
      // console.log('logout', email)
      await axios.post('https://manager.petergoodstrong.com/api/logout', {
        email,
        // returnSecureToken: true,
      })
        .then(() => {
          // console.log('sei dentro lo store/logout')
          // console.log(res)
          commit('clearAuthData')
          router.push('/login')
        })
        .catch(e => e)
    },
    async changePassword({ commit }, formData) {
      // console.log('changePassword', formData)
      await axios.post('https://manager.petergoodstrong.com/api/changePassword', {
        email: formData.email,
        password_confirmation: formData.cPassword,
        password: formData.password,
        // returnSecureToken: true,
      })
        .then(() => {
          // console.log('sei dentro lo store/changePassword')
          // onsole.log(res)
          commit('errorChangePassword', '')
        })
        .catch(() => {
          // console.log(e)
          const error = 'Email o Password errati!'
          commit('errorChangePassword', error)
        })
    },
    async resetPassword({ commit }, formData) {
      // console.log('resetPassword', formData)
      await axios.post('https://manager.petergoodstrong.com/api/resetPassword', {
        email: formData.email,
        // returnSecureToken: true,
      })
        .then(() => {
          // console.log('sei dentro lo store/resetPassword')
          // console.log(res)
          commit('errorResetPassword', '')
        })
        .catch(() => {
          // console.log(e)
          const errore = 'Email Errata!'
          commit('errorResetPassword', errore)
        })
    },
  },
}
